import React, { useState } from "react"
import { Divider, message, Switch } from "antd"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import { CpiService } from "../../../services/cpi.service"
import { authService } from "../../../services/auth.service"
import firebase from "gatsby-plugin-firebase"
import countryList from "react-select-country-list"
import { Link, navigate } from "gatsby"
import Protocol from "../../../components/protocol"
import Header from "../../../components/header"
import "react-quill/dist/quill.snow.css"
//quill build issue -- just keep it like this instead of import
const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false

const countryData = countryList()
  .getData()
  .map(city => {
    return (
      <option value={city.label} key={city.label}>
        {city.label}
      </option>
    )
  })
const strings = ["Violin", "Viola", "Double Bass", "Cello"]
const bows = ["Violin Bow", "Viola Bow", "Double Bass Bow", "Cello Bow"]

export default function NewIns() {
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const user = await authService.getUser()

      values.uid = user.uid
      values.userName = user.name
      values.organization = user.organization
      values.cpiStatus = "Pending"
      values.source = "cpr"

      Object.keys(values).forEach(key => {
        if (values[key] === undefined || values[key].length === 0) {
          delete values[key]
        }
      })

      // console.log(values)
      await CpiService.createIns(values)

      message.success(`Instrument Submitted Successfully.`, 2)

      navigate("/app/instrument/redirectsubmit")
    } catch (err) {
      console.log(err)
      message.error(`Error Submiting Form.`, 2)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className="h-screen max-h-screen" style={{ overflowY: "hidden" }}>
      <Header />
      <div className="flex flex-row" style={{ height: "calc(100% - 80px)" }}>
        <div className="mb-12 hidden sm:block md:w-1/3 pl-6 pr-3 py-3 overflow-y-scroll">
          <Protocol />
        </div>
        <div className="px-3 md:w-2/3 overflow-y-scroll mb-12 ">
          <Formik
            initialValues={{
              acceptTerms: false,
              name: "",
              type: "",
              workshop: "",
              makerFirst: "",
              makerLast: "",
              makerMiddle: "",
              makerBirth: "",
              makerDeath: "",
              year: "",
              country: "",
              state: "",
              city: "",
              label: "",
              description: "",
              narrative: "",
              note: "",
              length: "",
              weight: "",
              matStick: "",
              matFrog: "",
              matWinding: "",
              bowShape: "",
              lowerBout: "",
              middleBout: "",
              upperBout: "",
              stringStop: "",
              stringLength: "",
              saleName: "",
              salePhone: "",
              saleEmail: "",
              loanName: "",
              loanPhone: "",
              loanEmail: "",
              matIvory: false,
              matTorto: false,
              matWhale: false,
              onSale: false,
              onLoan: false,
            }}
            validationSchema={Yup.object({
              acceptTerms: Yup.boolean().oneOf(
                [true],
                "confirmation is required"
              ),
              name: Yup.string().required("name is required"),
              type: Yup.string().required("instrument type is required"),
              length: Yup.number().positive(),
              weight: Yup.number().positive(),
              lowerBout: Yup.number().positive(),
              middleBout: Yup.number().positive(),
              upperBout: Yup.number().positive(),
              stringStop: Yup.number().positive(),
              stringLength: Yup.number().positive(),
              year: Yup.number().positive(),
              makerBirth: Yup.number().positive(),
              makerDeath: Yup.number().positive(),
            })}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, isSubmitting, setFieldValue }) => (
              <Form className="w-full p-5">
                <h3 className="pb-2">Meta</h3>
                {/* col 1 -- reverse */}
                <div className="w-full md:pr-2 mb-6 md:mb-0">
                  {/* row 1 */}
                  <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full md:w-2/3 px-3">
                      <label htmlFor="name">Common Name*</label>
                      <Field
                        className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                        type="text"
                        name="name"
                      />
                      <ErrorMessage
                        className="text-red-500 text-xs italic"
                        name="name"
                        component="div"
                      />
                    </div>
                    <div className="w-full md:w-1/3 px-3 ">
                      <label htmlFor="type">Type*</label>

                      <div className="relative">
                        <Field
                          name="type"
                          as="select"
                          className="block appearance-none w-full border border-gray-300 text-gray-700 py-3 px-4 my-3 rounded leading-tight focus:outline-none focus:border-blue-500"
                        >
                          <option disabled value="">
                            Please Select
                          </option>
                          <option value="Violin">Violin</option>
                          <option value="Viola">Viola</option>
                          <option value="Cello">Cello</option>
                          <option value="Double Bass">Double Bass</option>
                          <option value="Piano">Piano</option>
                          <option value="Violin Bow">Violin Bow</option>
                          <option value="Viola Bow">Viola Bow</option>
                          <option value="Cello Bow">Cello Bow</option>
                          <option value="Double Bass Bow">
                            Double Bass Bow
                          </option>
                          <option value="Other">Other</option>
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                      <ErrorMessage
                        className="text-red-500 text-xs italic"
                        name="type"
                        component="div"
                      />
                    </div>
                  </div>
                  {/* row 2 */}{" "}
                  <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full md:w-2/3 px-3 ">
                      <label htmlFor="workshop">Workshop or Manufacturer</label>
                      <Field
                        className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                        type="text"
                        name="workshop"
                      />
                      <ErrorMessage
                        className="text-red-500 text-xs italic"
                        name="workshop"
                        component="div"
                      />
                    </div>
                    <div className="w-full md:w-1/3 px-3 ">
                      <label htmlFor="madeyear">Made Year</label>
                      <Field
                        className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                        type="text"
                        name="year"
                      />
                      <ErrorMessage
                        className="text-red-500 text-xs italic"
                        name="year"
                        component="div"
                      />
                    </div>
                  </div>
                </div>
                {/* row 3*/}
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-1/3 px-3 ">
                    <label htmlFor="madecity">Made City</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      type="text"
                      name="city"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="city"
                      component="div"
                    />
                  </div>
                  <div className="w-full md:w-1/3 px-3 ">
                    <label htmlFor="madestate">Made State</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      type="text"
                      name="state"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="state"
                      component="div"
                    />
                  </div>

                  <div className="w-full md:w-1/3 px-3 ">
                    <label htmlFor="madecountry">Made Country</label>
                    <div className="relative">
                      <Field
                        name="country"
                        as="select"
                        className="block appearance-none w-full border border-gray-300 text-gray-700 py-3 px-4 my-3 rounded leading-tight focus:outline-none focus:border-blue-500"
                      >
                        <option disabled value="">
                          Please Select
                        </option>
                        {countryData}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="country"
                      component="div"
                    />
                  </div>
                </div>
                {/* row 4*/}
                <h4 className="mt-2 mb-3">Maker Info</h4>
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-1/4 px-3">
                    <label htmlFor="firstname">First Name</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      type="text"
                      name="makerFirst"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="makerFirst"
                      component="div"
                    />
                  </div>
                  <div className="w-full md:w-1/6 px-3">
                    <label htmlFor="middlename">Middle Name</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      type="text"
                      name="makerMiddle"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="makerMiddle"
                      component="div"
                    />
                  </div>
                  <div className="w-full md:w-1/4 px-3">
                    <label htmlFor="lastname">Last Name</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      type="text"
                      name="makerLast"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="makerLast"
                      component="div"
                    />
                  </div>
                  <div className="w-full md:w-1/6 px-3">
                    <label htmlFor="birthyear">Birth Year</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      type="text"
                      name="makerBirth"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="makerBirth"
                      component="div"
                    />
                  </div>
                  <div className="w-full md:w-1/6 px-3">
                    <label htmlFor="deathyear">Death Year</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      type="text"
                      name="makerDeath"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="makerDeath"
                      component="div"
                    />
                  </div>
                </div>
                {/* row 6 */}
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full px-3 ">
                    <label htmlFor="label">Label</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      component="textarea"
                      rows="4"
                      name="label"
                    ></Field>
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="label"
                      component="div"
                    />
                  </div>
                </div>
                {/* row 7 */}
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full px-3 ">
                    <label htmlFor="description">Physical Description</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      component="textarea"
                      rows="4"
                      name="description"
                    ></Field>
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="description"
                      component="div"
                    />
                  </div>
                </div>
                {/* row 8 */}
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full px-3">
                    <label htmlFor="narrative">Narrative</label>
                    <div className=" appearance-none block w-full py-3 h-36 mb-2">
                      <Field name="narrative">
                        {({ field }) => {
                          return (
                            <ReactQuill
                              style={{ height: "100px" }}
                              theme="snow"
                              value={field.value}
                              onChange={field.onChange(field.name)}
                            />
                          )
                        }}
                      </Field>
                    </div>
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="narrative"
                      component="div"
                    />
                  </div>
                </div>
                {/* row 9 */}
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full px-3 ">
                    <label htmlFor="note">Note</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      component="textarea"
                      rows="4"
                      name="note"
                    ></Field>
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="note"
                      component="div"
                    />
                  </div>
                </div>
                <Divider />
                {/* group 2 */}
                <h3 className="pb-4">Endangered Species Material</h3>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full mb-3 md:mb-0 md:w-1/3 px-3 ">
                    {/* <Field type="checkbox" name="matIvory" />*/}
                    <label htmlFor="ivory"> Ivory </label>
                    <Switch
                      name="matIvory"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={value => setFieldValue("matIvory", value)}
                    />
                  </div>
                  <div className="w-full mb-3 md:mb-0 md:w-1/3 px-3 ">
                    {/* <Field type="checkbox" name="matTorto" /> */}
                    <label htmlFor="tortoiseshell"> Tortoiseshell </label>
                    <Switch
                      name="matTorto"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={value => setFieldValue("matTorto", value)}
                    />
                  </div>
                  <div className="w-full mb-3 md:mb-0 md:w-1/3 px-3 ">
                    {/* <Field type="checkbox" name="matWhale" /> */}
                    <label htmlFor="whalebone"> Whalebone </label>
                    <Switch
                      name="matWhale"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={value => setFieldValue("matWhale", value)}
                    />
                  </div>
                </div>
                {bows.includes(values.type) || values.type === "Other" ? (
                  <div className="flex flex-wrap -mx-3 mb-0">
                    <div className="w-full md:w-1/3 px-3">
                      <label htmlFor="matStick">Material of Stick</label>
                      <div className="relative">
                        <Field
                          className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                          type="text"
                          name="matStick"
                        />
                      </div>
                      <ErrorMessage
                        className="text-red-500 text-xs italic"
                        name="matStick"
                        component="div"
                      />
                    </div>
                    <div className="w-full md:w-1/3 px-3">
                      <label htmlFor="matWinding">Material of Winding</label>
                      <div className="relative">
                        <Field
                          className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                          type="text"
                          name="matWinding"
                        />
                      </div>
                      <ErrorMessage
                        className="text-red-500 text-xs italic"
                        name="matWinding"
                        component="div"
                      />
                    </div>
                    <div className="w-full md:w-1/3 px-3">
                      <label htmlFor="matFrog">
                        Materials of Frog/Adjuster
                      </label>
                      <div className="relative">
                        <Field
                          className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                          type="text"
                          name="matFrog"
                        />
                      </div>
                      <ErrorMessage
                        className="text-red-500 text-xs italic"
                        name="matFrog"
                        component="div"
                      />
                    </div>
                  </div>
                ) : null}
                <Divider />
                {/* group 3 */}
                <h3 className="pb-4">Measurement</h3>
                <div className="flex flex-wrap -mx-3 mb-6">
                  {values.type === "Piano" ? (
                    <>
                      <div className="w-full md:w-1/4 px-3">
                        <label htmlFor="length">Length</label>
                        <div className="relative">
                          <Field
                            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                            type="text"
                            name="length"
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            cm
                          </div>
                        </div>
                        <ErrorMessage
                          className="text-red-500 text-xs italic"
                          name="length"
                          component="div"
                        />
                      </div>{" "}
                      <div className="w-full md:w-1/4 px-3">
                        <label htmlFor="weight">Weight</label>
                        <div className="relative">
                          <Field
                            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                            type="text"
                            name="weight"
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            gm
                          </div>
                        </div>
                        <ErrorMessage
                          className="text-red-500 text-xs italic"
                          name="weight"
                          component="div"
                        />
                      </div>
                    </>
                  ) : null}

                  {strings.includes(values.type) ? (
                    <div className="w-full md:w-1/4 px-3">
                      <label htmlFor="length">Length of Back</label>
                      <div className="relative">
                        <Field
                          className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                          type="text"
                          name="length"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          cm
                        </div>
                      </div>
                      <ErrorMessage
                        className="text-red-500 text-xs italic"
                        name="length"
                        component="div"
                      />
                    </div>
                  ) : null}

                  {strings.includes(values.type) || values.type === "Other" ? (
                    <>
                      <div className="w-full md:w-1/4 px-3 ">
                        <label htmlFor="upperbout">Upper Bout</label>
                        <div className="relative">
                          <Field
                            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                            type="text"
                            name="upperBout"
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            cm
                          </div>
                        </div>
                        <ErrorMessage
                          className="text-red-500 text-xs italic"
                          name="upperBout"
                          component="div"
                        />
                      </div>
                      <div className="w-full md:w-1/4 px-3 ">
                        <label htmlFor="middlebout">Middle Bout</label>
                        <div className="relative">
                          <Field
                            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                            type="text"
                            name="middleBout"
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            cm
                          </div>
                        </div>
                        <ErrorMessage
                          className="text-red-500 text-xs italic"
                          name="middleBout"
                          component="div"
                        />
                      </div>
                      <div className="w-full md:w-1/4 px-3 ">
                        <label htmlFor="lowerbout">Lower Bout</label>
                        <div className="relative">
                          <Field
                            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                            type="text"
                            name="lowerBout"
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            cm
                          </div>
                        </div>
                        <ErrorMessage
                          className="text-red-500 text-xs italic"
                          name="lowerBout"
                          component="div"
                        />
                      </div>
                      <div className="w-full md:w-1/4 px-3">
                        <label htmlFor="stringstop">String Stop</label>
                        <div className="relative">
                          <Field
                            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                            type="text"
                            name="stringStop"
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            cm
                          </div>
                        </div>
                        <ErrorMessage
                          className="text-red-500 text-xs italic"
                          name="stringStop"
                          component="div"
                        />
                      </div>
                      <div className="w-full md:w-1/4 px-3">
                        <label htmlFor="stringlength">String Length</label>
                        <div className="relative">
                          <Field
                            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                            type="text"
                            name="stringLength"
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            cm
                          </div>
                        </div>
                        <ErrorMessage
                          className="text-red-500 text-xs italic"
                          name="stringLength"
                          component="div"
                        />
                      </div>
                    </>
                  ) : null}

                  {bows.includes(values.type) || values.type === "Other" ? (
                    <>
                      <div className="w-full md:w-1/4 px-3 ">
                        <label htmlFor="bowShape">Shape of Stick</label>
                        <div className="relative">
                          <Field
                            name="bowShape"
                            as="select"
                            className="block appearance-none w-full border border-gray-300 text-gray-700 py-3 px-4 my-3 rounded leading-tight focus:outline-none focus:border-blue-500"
                          >
                            <option disabled value="">
                              Please Select
                            </option>
                            <option value="Round">Round</option>
                            <option value="Octagonal">Octagonal</option>
                          </Field>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                        <ErrorMessage
                          className="text-red-500 text-xs italic"
                          name="bowShape"
                          component="div"
                        />
                      </div>
                      <div className="w-full md:w-1/4 px-3">
                        <label htmlFor="length">
                          {values.type === "Other"
                            ? "Length"
                            : "Length of Stick"}
                        </label>
                        <div className="relative">
                          <Field
                            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                            type="text"
                            name="length"
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            cm
                          </div>
                        </div>
                        <ErrorMessage
                          className="text-red-500 text-xs italic"
                          name="length"
                          component="div"
                        />
                      </div>

                      <div className="w-full md:w-1/4 px-3">
                        <label htmlFor="weight">Weight</label>
                        <div className="relative">
                          <Field
                            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                            type="text"
                            name="weight"
                          />
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            gm
                          </div>
                        </div>
                        <ErrorMessage
                          className="text-red-500 text-xs italic"
                          name="weight"
                          component="div"
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                <Divider />
                {/* group 4 */}
                <h3 className="pb-4">Sale and Loan</h3>
                {/* row 1 */}
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full mb-3 md:mb-0 md:w-1/3 px-3 ">
                    {/* <Field type="checkbox" name="onSale" /> */}
                    <label htmlFor="onSale"> For Sale </label>
                    <Switch
                      name="onSale"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={value => setFieldValue("onSale", value)}
                    />
                  </div>
                  <div className="w-full mb-3 md:mb-0 md:w-1/3 px-3 ">
                    {/* <Field type="checkbox" name="onLoan" /> */}
                    <label htmlFor="onLoan"> For Loan </label>
                    <Switch
                      name="onLoan"
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      onChange={value => setFieldValue("onLoan", value)}
                    />
                  </div>
                </div>
                {/* row 2 */}
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-1/3 px-3 ">
                    <label htmlFor="saleName">Sale Agent Name</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      type="text"
                      name="saleName"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="saleName"
                      component="div"
                    />
                  </div>
                  <div className="w-full md:w-1/3 px-3 ">
                    <label htmlFor="salePhone">Sale Agent Phone</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      type="text"
                      name="salePhone"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="salePhone"
                      component="div"
                    />
                  </div>
                  <div className="w-full md:w-1/3 px-3 ">
                    <label htmlFor="saleEmail">Sale Agent Email</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      type="text"
                      name="saleEmail"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="saleEmail"
                      component="div"
                    />
                  </div>
                </div>
                {/* row 3 */}
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-1/3 px-3 ">
                    <label htmlFor="loanName">Loan Agent Name</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      type="text"
                      name="loanName"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="loanName"
                      component="div"
                    />
                  </div>
                  <div className="w-full md:w-1/3 px-3 ">
                    <label htmlFor="loanPhone">Loan Agent Phone</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      type="text"
                      name="loanPhone"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="loanPhone"
                      component="div"
                    />
                  </div>
                  <div className="w-full md:w-1/3 px-3 ">
                    <label htmlFor="loanEmail">Loan Agent Email</label>
                    <Field
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 my-3 leading-tight focus:outline-none focus:border-blue-500 "
                      type="text"
                      name="loanEmail"
                    />
                    <ErrorMessage
                      className="text-red-500 text-xs italic"
                      name="loanEmail"
                      component="div"
                    />
                  </div>
                </div>
                <Divider />

                <Field type="checkbox" name="acceptTerms" />
                <label htmlFor="acceptTerms">
                  {" "}
                  I have read the{" "}
                  <a className="text-blue-700" href="">
                    Content Provider Agreement
                  </a>
                </label>
                <ErrorMessage
                  name="acceptTerms"
                  component="div"
                  className="text-red-500 text-xs italic"
                />

                <div className="w-full fixed bottom-0 right-0 bg-gray-200 ">
                  <div className="flex flex-row-reverse px-4 py-3 gap-3   ">
                    {/* <Link to={`/app/instrument/redirectsubmit`}> */}
                    <button
                      className=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                    {/* </Link> */}
                    <Link to={`/app/instrument`}>
                      <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md  font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}
